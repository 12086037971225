<template>
  <div :class="$style.main">
    <OrganismsLayoutDefault>
      <template #nuxt-page>
        <NuxtPage />
      </template>
    </OrganismsLayoutDefault>
  </div>
</template>

<style module lang="scss" scoped>
.main {
  min-width: 100%;
  min-height: 100vh;
  padding: 0;
  display: flex;
  flex-direction: column;
}
</style>
